// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import Rails from "@rails/ujs"
//Rails.start();
window.Rails = Rails;
if(Rails.fire(document, "rails:attachBindings")) {
    Rails.start();
}
import "./controllers";
import * as bootstrap from "bootstrap";
//import "@fortawesome/fontawesome-free/js/all"




