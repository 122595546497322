import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto"


// Connects to data-controller="grafici"
export default class extends Controller {
  static targets = ["graficoasse", "graficomoduli", "graficototale", "tabella", "tabella_np", "mostraDopo"]
  static values = {
    resultset: Array,
    modulilabels: Array, 
    moduliresults: Array, 
    url: String
  }

  colori = [
    'rgba(65, 201, 145, 0.3)',
    //'rgba(206, 151, 35, 0.3)',
    'rgba(245,189,81,0.3',
    'rgba(180, 35, 206, 0.3)',
    'rgba(35, 151, 206, 0.3)'
  ]

  title_font_size = 13
  subtitle_font_size = 11
  label_font_size = 12

  initialize() {
    fetch(this.urlValue)
    .then(response => response.json())
    .then((data) => 
    {
      for(var i = 0; i < data.assi.length; i++) {
        this.disegna_grafico(this.graficoasseTargets[i], data.assi[i], this.colori[i]);
      }  
      this.disegna_grafico_moduli(this.graficomoduliTarget, data);
      //this.disegna_grafico_totale(this.graficototaleTarget, data)
      //this.disegna_grafico_moduli2(this.graficomoduli2Target, data)
      this.popola_tabella(this._moduli_con_punteggi(data),4);
      this.popola_tabella_np(this._moduli_con_punteggi_non_ponderati(data),4);

      this.mostraDopoTargets.forEach(function(element) {
        console.log("Mostra", element);
        element.classList.remove("hidden");
      }); 
    });
  }

  _gestisci_unicode(etichetta) {
    switch (etichetta) {
      case "<sub>CO2</sub>":
        return "CO\u2082";
        break;
      default:
        return etichetta;
        break;
    }
  }

  _coloreBordo(colore) {
    return colore.replace("0.5","0.8")
  }

  _coloreTitolo(colore) {
    return colore.replace("0.3","0.8")
  }

  disegna_grafico(canvas, asse, colore) {    
    const ctx = canvas.getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'radar',
      data: {
          labels: this._etichette_moduli(asse),
          datasets: [{
              label: "Ponderato",//asse.punteggio + "%",//"Ponderato", //asse.nome,//this._etichette_moduli(asse),//.nome,
              backgroundColor: colore,
              borderColor: this._coloreBordo(colore),
              data: this._punteggi_moduli(asse),
          },
          {
            label: "Non Ponderato",//asse.punteggio_non_ponderato + "%",//"Non Ponderato", //this._etichette_moduli(asse),//.nome,
            backgroundColor: "#e0e0e0",
            //borderColor: this._coloreBordo(colore),
            data: this._punteggi_moduli_non_ponderati(asse),
          }
        ]
      }, 
      options: {
        plugins: {
          legend: {
            display: false,
            //onClick: null
            labels: {
              font: {
                size: this.label_font_size
              },
            },
          },
          title: {
            display: true,
            font: {
              size: this.title_font_size,
            },
            color: this._coloreTitolo(colore),
            text: asse.nome + " " + asse.punteggio + "%" + " ("+asse.punteggio_non_ponderato + "% NP)",
          },
          subtitle: {
            display: false,
            text: asse.punteggio_non_ponderato + "%",
            font: {
              size: this.subtitle_font_size,
            }
          }
        },
        scales: {
          r: {
            pointLabels: {
              font: {
                size: 10
              },
            },
            ticks: {
              display: false
            },
              suggestedMin: 0,
              suggestedMax: 100
          }
        }
      }
    });
  }

  popola_tabella(punteggi, n) {
    for (var i = 0; i < n; i++) {
      this.tabellaTarget.innerHTML += `
        <tr>
          <td><span class='cerchio verde'>${punteggi[punteggi.length - i - 1][1]}%</span> <span class='etichetta'>${punteggi[punteggi.length - i - 1][0]}</span></td>
          <td><span class='cerchio rosso'>${punteggi[i][1]}%</span><span class='etichetta'>${punteggi[i][0]}</span></td>
        </tr>
        `
    }
  }

  popola_tabella_np(punteggi, n) {
    for (var i = 0; i < n; i++) {
      this.tabella_npTarget.innerHTML += `
        <tr>
          <td><span class='cerchio verde'>${punteggi[punteggi.length - i - 1][1]}%</span> <span class='etichetta'>${punteggi[punteggi.length - i - 1][0]}</span></td>
          <td><span class='cerchio rosso'>${punteggi[i][1]}%</span><span class='etichetta'>${punteggi[i][0]}</span></td>
        </tr>
        `
    }
  }

  disegna_grafico_moduli(canvas, data) {    
    const ctx = canvas.getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'radar',
      data: {
          labels: this._tutte_etichette_moduli(data).flat(),
          datasets: [{
              label: "Ponderato",//data.punteggio + "%",//"Ponderato",
              backgroundColor: 'rgba(214, 167, 0, 0.3)',//this.colori[0],
              borderColor: 'rgba(214, 167, 0, 1)',//this._coloreBordo(this.colori[0]),
              //pointBackgroundColor: this._colori_moduli(this.colori),
              data: this._tutti_i_punteggi_moduli(data).flat(),
          },
          {
            label: "Non Ponderato",//data.punteggio_non_ponderato + "%",//"Non Ponderato",
            //backgroundColor: this.colori[0],
            //borderColor: this._coloreBordo(this.colori[0]),
            //pointBackgroundColor: this._colori_moduli(this.colori),
            data: this._tutti_i_punteggi_moduli_non_ponderati(data).flat(),
          }
        ]
      }, 
      options: {
        plugins: {
          legend: {
            display: false,
            //onClick: null
          },
          title: {
            display: true,
            text: "Totale " + data.punteggio + "%" + " (" + data.punteggio_non_ponderato + "% NP)",
            color: 'rgba(214, 167, 0, 1)',
            font: {
              size: this.title_font_size,
            },
          },
          subtitle: {
            display: false,
            text: data.punteggio_non_ponderato + "%",
            font: {
              size: this.subtitle_font_size,
            },
          }
        },
        scales: {
          r: {
            pointLabels: {
              font: {
                size: this.label_font_size
              },
            },
            ticks: {
              display: false
            },
              suggestedMin: 0,
              suggestedMax: 100
          }
        }
      }
    });
  }

  /*
  disegna_grafico_totale(canvas, data) {    
    const ctx = canvas.getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'radar',
      data: {
          labels: this._etichette_assi(data),
          datasets: [{
              label: "Ponderato",
              //backgroundColor: this.colori,
              //borderColor: this._coloreBordo(this.colori,
              //borderColor: this.colori,
              pointBackgroundColor: this.colori,
              data: this._punteggi_assi(data),
          },
          {
            label: "Non Ponderato",
            data: this._punteggi_assi_non_ponderati(data)
          }
        ]
      }, 
      options: {
        plugins: {
          legend: {
            //display: false,
            //onClick: null
          },
          title: {
            display: true,
            text: "Totale",
            font: {
              size: this.title_font_size,
            },
          }
        },
        scales: {
          r: {
            pointLabels: {
              font: {
                size: 18
              },
            },
            ticks: {
              display: false
            },
              suggestedMin: 0,
              suggestedMax: 100
          }
        }
      }
    });
  }
  */

  /*
  disegna_grafico_moduli2(canvas, data) {   
    const ctx = canvas.getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'radar',
      data: {
          labels: this._tutte_etichette_moduli(data).flat(),
          spanGaps: true,
          datasets: [{
              label: "A",
              backgroundColor: this.colori[0],
              borderColor: this._coloreBordo(this.colori[0]),
              data: this._tutti_i_punteggi_moduli2(data, 0).flat()
            },
            {
              label: "B",
              backgroundColor: this.colori[1],
              borderColor: this._coloreBordo(this.colori[1]),
              data: this._tutti_i_punteggi_moduli2(data, 1).flat(),
            },
            {
              label: "C",
              backgroundColor: this.colori[2],
              borderColor: this._coloreBordo(this.colori[2]),
              data: this._tutti_i_punteggi_moduli2(data, 2).flat(),
            },
            {
              label: "D",
              backgroundColor: this.colori[3],
              borderColor: this._coloreBordo(this.colori[3]),
              data: this._tutti_i_punteggi_moduli2(data, 3).flat(),
            },
        ]
      }, 
      options: {
          scales: {
              r: {
                  //suggestedMin: 0,
                  //suggestedMax: 36
              }
  
          }
          }
    });
  }
  */

 _moduli_con_punteggi(data) {
   let risultati = [];
   data.assi.map(function(asse) {
     asse.moduli.map(function(modulo) {
      risultati.push([modulo.etichetta, modulo.punteggio ]);
    });
  });
  return risultati.sort(function(a,b) { return a[1] - b[1]; });
 }

 _moduli_con_punteggi_non_ponderati(data) {
  let risultati = [];
  data.assi.map(function(asse) {
    asse.moduli.map(function(modulo) {
     risultati.push([modulo.etichetta, modulo.punteggio_non_ponderato ]);
   });
 });
 return risultati.sort(function(a,b) { return a[1] - b[1]; });
}

  _etichette_moduli(asse) {
    return asse.moduli.map(function(modulo) {
      if (modulo.etichetta == "CO<sub>2</sub>") {
        return "CO\u2082";
      }
      else {
        return modulo.etichetta;
      }
    });
  }

  _tutte_etichette_moduli(data) {
    return data.assi.map(function(asse) {
      return asse.moduli.map(function(modulo) {
        //return modulo.etichetta;
        if (modulo.etichetta == "CO<sub>2</sub>") {
          return "CO\u2082";
        }
        else {
          return modulo.etichetta;
        }
      })
    })
  }

  _etichette_assi(data) {
    return data.assi.map(function(asse) {
      return asse.nome
    })
  }

  _punteggi_moduli(asse) {
    return asse.moduli.map(function(modulo) {
      return modulo.punteggio
    })
  }
  
  _tutti_i_punteggi_moduli(data) {
    return data.assi.map(function(asse) {
      return asse.moduli.map(function(modulo) {
        return modulo.punteggio
      })
    })
  }

  _punteggi_assi(data) {
    return data.assi.map(function(asse) {
      return asse.punteggio
    })
  }

  _punteggi_moduli_non_ponderati(asse) {
    return asse.moduli.map(function(modulo) {
      return modulo.punteggio_non_ponderato
    })
  }
  
  _tutti_i_punteggi_moduli_non_ponderati(data) {
    return data.assi.map(function(asse) {
      return asse.moduli.map(function(modulo) {
        return modulo.punteggio_non_ponderato
      })
    })
  }

  _punteggi_assi_non_ponderati(data) {
    return data.assi.map(function(asse) {
      return asse.punteggio_non_ponderato
    })
  }


  _colori_moduli() {
    return this.colori.map(function(colore) {
      return [colore, colore, colore, colore]
    }).flat()
  }


  connect() {
    //this.disegna_grafico(this.element)
    //this.disegna_grafico2(this.element)
  }
  
  /*
  disegna_grafico(e) {
    const ctx = document.getElementById("generale").getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'radar',
      data: {
          labels: ['Ambiente', 'Società', 'Governance', 'Prodotto'],
          datasets: [{
              label: 'esgp',
              data: this.resultsetValue,
          }]
      },
      options: {
          scales: {
              r: {
                  suggestedMin: 0,
                  suggestedMax: 100
              }
  
          }
          }
    });
  }

  disegna_grafico2(e) {
    const ctx = document.getElementById("per_moduli").getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'radar',
      data: {
          labels: this.modulilabelsValue,
          datasets: [{
              label: "Tutti",
              data: this.moduliresultsValue,
          },
        ]
      },
      options: {
          scales: {
              r: {
                  //beginAtZero: true,
                  //suggestedMin: 0,
                  //suggestedMax: 100
              }
  
          }
          }
    });
  }
  */
}

