import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="questionari"
export default class extends Controller {

  static targets = [ "materialita", "valutazione" ]

  connect() {
    //console.log(this.materialitaTarget)
    console.log(this.valutazioneTarget)
  }

  /*
  selezionaMaterialita() {
    console.log("Materialita", document.getElementById("salva_risposta").querySelectorAll("input[type='radio']:checked").length)
    this.nel_caso_submit()
  }*/

  selezionaValutazione() {
    console.log("Valutazione", document.getElementById("salva_risposta").querySelectorAll("input[type='radio']:checked").length);
    console.log(this.element.dataset.disable);
    if (this.element.dataset.disable == undefined) {
      Rails.fire(document.getElementById("salva_risposta"), 'submit');
    }
    this.element.dataset.disable = true;
    console.log(this.element);

    //this.nel_caso_submit()
  }

  inviata() {
    console.log("inviata con successo");
  }

  /*
  nel_caso_submit() {
    console.log("invio?")
    if (document.getElementById("salva_risposta").querySelectorAll("input[type='radio']:checked").length == 2) {
      console.log("si")
      Rails.fire(document.getElementById("salva_risposta"), 'submit');
    }
  }
  */

  /*
  mostraValutazione(event) {
    event.preventDefault()
    this.materialitaTarget.classList.add("hide");
    this.valutazioneTarget.classList.remove("hide");
  }

  mostraMaterialita(event) {
    event.preventDefault()
    this.valutazioneTarget.classList.add("hide");
    this.materialitaTarget.classList.remove("hide");
  }
  */

  
}
