import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="intro"
export default class extends Controller {
  static targets = ["intro", "intro1", "intro2", "intro3", "intro4"
]
  connect() {
    console.log("connected")
  }

  mostra1() {
    this._nascondi()
    this.intro1Target.classList.remove("hidden")
  }

  mostra2() {
    this._nascondi()
    this.intro2Target.classList.remove("hidden")
  }

  mostra3() {
    this._nascondi()
    this.intro3Target.classList.remove("hidden")
  }

  mostra4() {
    this._nascondi()
    this.intro4Target.classList.remove("hidden")
  }

  _nascondi() {
    this.introTargets.forEach((intro) => {
      intro.classList.add("hidden")
    })
  }
}
